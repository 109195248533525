@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #012466;
  font-weight: 300;
  background-image: url('/public//img//bannerOP.png');
  background-size: contain;
}
.myContainer {
  max-width: 1500px;
  margin: 0 auto;
  padding: 5% 5% 8% 5%;
  overflow: hidden;
}
.butPink {
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.25rem;
  line-height: 1.5rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0 2rem;
  padding: 0.125rem 3.625rem 0;
  cursor: pointer;
  outline: 0;
  white-space: nowrap;
  z-index: 2;
  position: relative;
  background: linear-gradient(to top, #ff0080, #ff4c9c);
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}
.button,
.button:after {
  background: linear-gradient(to top, #ff0080, #ff4c9c);
  transition: all 0.25s ease-in-out;
}
.myBanner {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 56rem;
  display: flex;
  align-items: center;
  background: linear-gradient(125.95deg, #ff0080 10.95%, #01b6fa 100%),
    linear-gradient(222.34deg, #ff0080 12.99%, #00ffe0 87.21%),
    linear-gradient(130.22deg, #8fa600 18.02%, #01b6fa 100%);
  background-blend-mode: screen, color-dodge, color-dodge;
}
.myBanner::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 36rem;
  background-image: url(/public/img/line.svg);
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
input:focus {
  outline: none;
}
.image_why_us {
  display: block;
  position: absolute;
  top: 1.25rem;
  left: 20px;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  background: linear-gradient(125.95deg, #ff0080 10.95%, #01b6fa 100%),
    linear-gradient(222.34deg, #ff0080 12.99%, #00ffe0 87.21%),
    linear-gradient(130.22deg, #8fa600 18.02%, #01b6fa 100%);
  background-blend-mode: screen, color-dodge, color-dodge, normal;
  box-shadow: 0 24px 20px -10px rgb(1 182 250 / 40%);
  opacity: 0.8;
}
.myShadow {
  box-shadow: 0 8px 40px -4px rgb(76 0 47 / 20%);
}
.bg_money {
  background: linear-gradient(238.72deg, #01b6fa 0, #ff0080 100%),
    radial-gradient(100% 148.07% at 0 0, #ff1989 0, #19c2fa 100%);
  background-blend-mode: hard-light, overlay, color-burn, color-burn, normal;
  background-size: cover;
}
.bg_footer {
  background: radial-gradient(circle at bottom right, #013a59 0, #015c8c 100%);
}
.line_blue {
  content: "";
  position: absolute;
  bottom: 1.375rem;
  right: calc(100% + 1rem);
  width: 100vw;
  height: 0.1875rem;
  background: linear-gradient(to right, #01b6fa, #7fe7fa);
  border-radius: 0.0675rem;
}
.line_blue_why {
  content: "";
  position: absolute;
  bottom: 1.375rem;
  left:calc(100% - 10rem);
  width: 100vh;
  height: 0.1875rem;
  background: linear-gradient(to right, #01b6fa, #7fe7fa);
  border-radius: 0.0675rem;
}
